import config from "./config";
import axios from 'axios'
import router from '../router/index'
import {showLoadingToast } from 'vant';
import { showToast } from 'vant';
import store from '../store'
import { isMobile,loginOut } from '../utils/validate'
// var isMobile = navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i);
var params = {};
var token = "";
var lang = "";
// if(isMobile) {
//     params.plat = 'h5'
// } else {
//     params.plat = 'pc'
// }

function getRequest(url,data,callback,loadingJudge,finallyCallback) {
    // if(localStorage.getItem('language')) {
    //     params.lang = localStorage.getItem('language');
    // }
    if(localStorage.getItem('AutoToken')) {
        token = localStorage.getItem('AutoToken');
    }
    if(localStorage.getItem('language')) {
        lang = localStorage.getItem('language');
    }
    let loading;
    if(loadingJudge) {
        loading = showLoadingToast({
            forbidClick: true,
        });
    }
    axios({
        method: 'GET',
        url: config.BASE_API + url,
        headers: {
            token: token,
            lang: lang
        },
        params: Object.assign(data, params)
    }).then((res) => {
        if(loadingJudge) {
            loading.close();
        }
        if(res.data.code == '10020' || res.data.code == '10021') {
            loginOut();
            if(isMobile()) {
                router.push('/h5/index')
            } else {
                router.push('/')
            }
        } else if (res.data.code == '0') {
            typeof callback === 'function' && callback(res.data)
        } else {
            showToast(res.data.msg)
        }
        typeof finallyCallback === 'function' && finallyCallback(res.data)
    })
}

function postRequest(url,data,callback,loadingJudge,errCallback) {
    // if(localStorage.getItem('language')) {
    //     params.lang = localStorage.getItem('language');
    // }
    if(localStorage.getItem('AutoToken')) {
        token = localStorage.getItem('AutoToken');
    }
    if(localStorage.getItem('language')) {
        lang = localStorage.getItem('language');
    }
    let loading;
    if(loadingJudge) {
        loading = showLoadingToast({
            forbidClick: true,
        });
    }
    axios({
        method: 'POST',
        url: config.BASE_API + url,
        headers:{'content-type':'application/json',token: token,lang: lang},
        data: JSON.stringify(Object.assign(data, params))
    }).then((res) => {
        if(loadingJudge) {
            loading.close();
        }
        if(res.data.code == '10020' || res.data.code == '10021') {
            loginOut();
            if(isMobile()) {
                router.push('/h5/index')
            } else {
                router.push('/')
            }
        } else if (res.data.code == '0') {
            typeof callback === 'function' && callback(res.data)
        } else if (res.data.code == '50003') {
            typeof errCallback === 'function' && errCallback(res.data)
        } else {
            showToast(res.data.msg)
        }
    })
}

export default {
    getRequest: getRequest,
    postRequest: postRequest
}

