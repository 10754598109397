<script setup>

import selectComponent from '@/components/H5/selectComponent.vue';
import {defineComponent} from "vue";

defineComponent({
  components: {
    selectComponent,
  }
})
</script>

<template>
  <div class="tkShow">
    <div class="mask"></div>
    <transition name="bounce"   enter-active-class="animate__animated animate__fadeInLeft"
                leave-active-class="animate__animated animate__fadeOutLeft">
      <div class="tkShow-content1">
        <div class="tu-main">
          <div class="tm-title divFlex">
            <img src="@/assets/images/component/return.png" alt="">
            <span>Registro de Depósito</span>
          </div>
          <div class="tur-body">
            <div class="divFlex title">
              <div class="left"><select-component /></div>
              <div class="right">
                Depósito Total
                <span>R$ 0,00</span>
              </div>
            </div>
            <ul>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>


</template>

<style scoped lang="scss">
.tkShow-content1{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1002;
  .tu-main{
    width: 100%;
    height: 90vh;
    background-color: $bg-color1;
    border: .01rem solid $bg-color3;
    border-radius: .12rem .12rem 0 0;
    margin: 0 auto;
    padding: .2rem .3rem;
    .tm-title{
      position: relative;
      text-align: center;
      justify-content: center;
      img{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        width: .2rem;
      }
      span{
        font-size: .3rem;
        color: $font-color1;
      }
      i{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        color: $font-color4;
        font-style: normal;
      }
    }
    .tur-body{
      padding-top: .4rem;
      .title{
        .right{
          span{
            margin-left: .1rem;
            color: $font-color1;
          }
        }
      }
    }
  }
}
</style>