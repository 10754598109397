import { createStore } from 'vuex'

export default createStore({
  state: {
      loginWindow: 1,   //  登录 1  注册  2
      loginWindowJudge: 1,  // 登录弹窗弹起判断
      autoToken:  localStorage.getItem('AutoToken')? localStorage.getItem('AutoToken') : '',
      userInfo: localStorage.getItem('UserInfo') && localStorage.getItem('UserInfo') != undefined ? JSON.parse(localStorage.getItem('UserInfo')) : {},
      remMultiple: 0,   // 移动端样式倍率
      commonConfigInfo: {},  // 网站通用数据
      moneyUrl: {},  // 链接
      deviceType: '',  //1 安卓   2 ios   3 pc
      shareCode: '', // 邀请码

  },
  getters: {
    loginWindow: (state) => state.loginWindow,
    loginWindowJudge: (state) => state.loginWindowJudge,
    autoToken: (state) => state.autoToken,
    userInfo: (state) => state.userInfo,
    remMultiple: (state) => state.remMultiple,
    commonConfigInfo: (state) => state.commonConfigInfo,
    moneyUrl: (state) => state.moneyUrl,
    deviceType: (state) => state.deviceType,
    shareCode: (state) => state.shareCode
  },
  mutations: {
    setLoginWindow(state, type) {
      state.loginWindow = type
    },
    setLoginWindowJudge(state, type) {
      state.loginWindowJudge = type
    },
    setAutoToken(state, type) {
      state.autoToken = type
    },
    setUserInfo(state, type) {
      state.userInfo = type
    },
    setRemMultiple(state, type) {
      state.remMultiple = type
    },
    setCommonConfigInfo(state, type) {
      state.commonConfigInfo = type
    },
    setMoneyUrl(state, type) {
      state.moneyUrl = type
    },
    setDeviceType(state, type) {
      state.deviceType = type
    },
    setShareCode(state, type) {
      state.shareCode = type
    }
  },
  modules: {
  }
})
