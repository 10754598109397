<script setup>
import {defineProps, reactive, watch,} from "vue";

const props = defineProps({
  value: String,
});

const pcData = reactive({
  showNum: 0,
  iNum: [1,2,3,4]
})

const validatePassword = (password) => {
  // Regular expression to match 6-16 characters with at least two letters/digits/symbols
  let regex = /^(?=.*[A-Za-z])(?=.*\d|.*[\W_])[A-Za-z\d\W_]{6,16}$/;
  if (!regex.test(password)) {
    return {
      isValid: false,
      percentage: 0
    };
  }
  // Count occurrences of letters (a-z, A-Z), digits (0-9), and symbols
  let letterCount = /^(?=(.*[A-Za-z]){1,})/.test(password);
  let digitCount = /(?=(.*\d){1,})/.test(password);
  let symbolCount = /(?=(.*[\W_]){1,})/.test(password);
  let fourCount = /[\w\W]{6,16}/.test(password);
  // Determine how many types of characters are present
  let typesCount = (letterCount ? 1 : 0) + (digitCount ? 1 : 0) + (symbolCount ? 1 : 0) + (fourCount ? 1 : 0);
  // Calculate the compliance percentage
  let percentage = (typesCount / 4) * 100; // Adjust according to your criteria
  if(percentage > 0 && percentage <= 25) {
    percentage = 25;
  } else if(percentage > 25 && percentage <= 50) {
    percentage = 50;
  } else if(percentage > 50 && percentage <= 75) {
    percentage = 75;
  } else {
    percentage = 100;
  }
  return {
    isValid: true,
    percentage: percentage
  };
}

watch(() => props.value, () => {
  if(props.value) {
    pcData.showNum = validatePassword(props.value).percentage;
  } else {
    pcData.showNum = 0;
  }

})

</script>

<template>
<div class="pc-main">
  <span class="middle">Força</span>
  <div class="middle">
    <i :class="pcData.showNum >= item*25?'middle active':'middle'" v-for="item in pcData.iNum" :key="item">{{}}</i>
  </div>
</div>
</template>

<style scoped lang="scss">
.pc-main{
  display: inline-block;
  span{
    font-size: .22rem;
    color: $font-color1;
    margin-right: .1rem;
  }
  //div{
  //  font-size: 0;
  //  background-color: $bg-color3;
  //  border-radius: .12rem;
  //  width: 4.5rem;
  //  height: .12rem;
  //  position: relative;
  //}
  i{
    border-radius: .12rem;
    height: .12rem;
    width: .8rem;
    background-color: $bg-color3;
    margin-right: .1rem;
    &.active{
      background-color: $font-color4;
    }
  }
}
</style>