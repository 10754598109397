<script setup>
import {reactive, defineProps, defineEmits, onMounted, defineComponent, ref} from 'vue';
import { showToast } from 'vant';
import {loginPost,userWalletGet,registerPost,levelListGet} from '@/utils/api';
import {loginIn, isPhoneNumber, isEmail} from "@/utils/validate";
import { useStore } from "vuex";
import progressCom from '@/components/H5/progressComponent.vue';

defineComponent({
  components: {
    progressCom
  }
})
const loginRef = ref(null);
const registerRef = ref(null);
// const { t } = useI18n()
const store = useStore();
const props = defineProps({
  tkShow: Boolean,
});
const emit = defineEmits(['close']);

const validateEqual = (rule, value, callback) => {
  if (value !== loginRegisterData.registerInfo.password) {
    callback(new Error('Confirme a senha novamente, o mesmo que a senha!'));
  } else {
    callback();
  }
}
const loginRegisterData = reactive({
  show: props.tkShow,
  loginInfo: {
    username: '',
    password: '',
    automatic: false,
    rules:{
      username: [
        { required: true, message: 'Nome de Usuário', trigger: 'blur' },
      ],
      password: [
        { required: true, message: 'Senha', trigger: 'blur' },
      ],
    }
  },
  type: store.state.loginWindow,
  telAndMail: 1,  // 电话  1  邮箱  2
  deviceInfo: {
    ua: ''
  },
  registerInfo: {
    truePassword: "",
    password: "",
    shareCode: "",
    username: "",
    realName: "",
    automatic: false,
    mobile: "",
    rules: {
      username: [
        { required: true, message: 'Por favor insira seu e-mail ou número de celular', trigger: 'blur' },
      ],
      password: [
        { required: true, message: 'Nome de Usuário', trigger: 'blur' },
      ],
      realName: [
        { required: true, message: 'Preencha o nome verdadeiro e torne -o conveniente para a retirada posterior!', trigger: 'blur' },
      ],
      truePassword: [
        { required: true, message: 'Confirme a senha novamente, o mesmo que a senha!', trigger: 'blur' },
        { validator: validateEqual, trigger: 'blur' }
      ],
    }
  },
  truePasswordEye: false,
  passwordEye: false,
  changePassword: {
    dialogShow: false
  },

})


// 关闭弹窗
const closeTk = () => {
  emit('close')
}

// 默认缓存登录信息
const loginAutomatic = () => {
  if(loginRegisterData.loginInfo.automatic) {
    let obj = {
      username: loginRegisterData.loginInfo.username,
      password: loginRegisterData.loginInfo.password,
    }
    localStorage.setItem("loginInfo", JSON.stringify(obj));
  } else {
    localStorage.removeItem("loginInfo");
  }
}

// 登录
const loginBtn = () => {
  loginRef.value.validate((valid) => {
    if(valid) {
      let _loginInfo = loginRegisterData.loginInfo;
      loginPost({
        "deviceInfo": JSON.stringify(loginRegisterData.deviceInfo),
        "password": _loginInfo.password,
        "username": _loginInfo.username,
      }, (res) => {
        loginAutomatic();
        showToast(res.msg);
        if(res.code == 0) {
          loginIn(res.data.token, {});
          userWalletGet((val) => {
            let obj =  Object.assign(res.data.user, val.data);
            loginIn('', obj);
            closeTk();
            levelListGetFun();
          })
        }
      })
    }
  })
}

// 注册
const registerBtn = () => {
  registerRef.value.validate((valid) => {
    if(valid) {
      const _registerInfo = loginRegisterData.registerInfo;
      if(!_registerInfo.automatic) {
        showToast("Tenho mais de 18 anos, li e aceito");
        return;
      }
      if(store.state.shareCode) {
        _registerInfo.shareCode = store.state.shareCode
      }
      if(localStorage.getItem('shareCode')) {
        _registerInfo.shareCode = localStorage.getItem('shareCode')
      }

      registerPost({
        "deviceInfo": JSON.stringify(loginRegisterData.deviceInfo),
        "password": _registerInfo.password,
        "shareCode": _registerInfo.shareCode,
        "username": _registerInfo.username,
        "realName": _registerInfo.realName,
        "mobile":_registerInfo.mobile
      },(res) => {
        if(res.code == '0') {
          loginIn(res.data.token, {});
          userWalletGet((val) => {
            let obj =  Object.assign(res.data.user, val.data)
            loginIn('', obj);
            closeTk();
            levelListGetFun();
          })
        }
      })
    }
  })


  // if(!_registerInfo.username) {
  //   showToast("Por favor insira seu e-mail ou número de celular");
  //   return;
  // }
  // if(!_registerInfo.password) {
  //   showToast("Senha");
  //   return;
  // }
  // if(!_registerInfo.truePassword) {
  //   showToast("Por favor confirme sua senha");
  //   return;
  // }
  // if(_registerInfo.password != _registerInfo.truePassword) {
  //   showToast("As duas senhas são inconsistentes");
  //   return;
  // }
  // if(!_registerInfo.realName) {
  //   showToast("Preencha o nome verdadeiro e torne -o conveniente para a retirada posterior!");
  //   return;
  // }

  // if(loginRegisterData.telAndMail == '1' && !isPhoneNumber(_registerInfo.username)) {
  //   showToast("o número de telefone está incorreto");
  //   return;
  // }
  // if(loginRegisterData.telAndMail == '2' && !isEmail(_registerInfo.username)) {
  //   showToast("O e-mail está incorreto");
  //   return;
  // }

};

// VIP等级
const levelListGetFun = () => {
  levelListGet((res) => {
    localStorage.setItem("vipList", JSON.stringify(res.data));
  })
}
// 客服跳转
const customerJump = () => {
  window.open(store.state.commonConfigInfo.customerService, '_blank')
};

onMounted(() => {
  loginRegisterData.deviceInfo.ua = navigator.userAgent;
  if(localStorage.getItem("loginInfo")) {
    let userInfo = JSON.parse(localStorage.getItem("loginInfo"));
    loginRegisterData.loginInfo.username = userInfo.username;
    loginRegisterData.loginInfo.password = userInfo.password;
    loginRegisterData.loginInfo.automatic = true;
  }
  // 分享码
  if(store.state.shareCode || localStorage.getItem('shareCode')) {
    loginRegisterData.registerInfo.shareCode = store.state.shareCode?store.state.shareCode : localStorage.getItem('shareCode');
  }
})

</script>

<template>
<div class="lr-main">
  <div class="mask" v-if="loginRegisterData.show" @click="closeTk"></div>
  <transition name="bounce"   enter-active-class="animate__animated animate__fadeInLeft"
              leave-active-class="animate__animated animate__fadeOutLeft">
    <div class="lr-m-body" v-if="loginRegisterData.show">
      <div class="lr-mb-content" v-if="!loginRegisterData.changePassword.dialogShow">
        <div class="title divFlex">
          <div :class="loginRegisterData.type === 2?'active':''" @click="loginRegisterData.type = 2">
            <img v-if="loginRegisterData.type === 2" class="middle" src="@/assets/images/component/r1-1.png" alt="">
            <img v-else class="middle" src="@/assets/images/component/r1.png" alt="">
            <span class="middle">Inscrever-se</span>
          </div>
          <div :class="loginRegisterData.type === 1?'active':''" @click="loginRegisterData.type = 1">
            <img v-if="loginRegisterData.type === 1"  class="middle" src="@/assets/images/component/r2-2.png" alt="">
            <img v-else  class="middle" src="@/assets/images/component/r2.png" alt="">
            <span class="middle">Acesso de membro</span>
          </div>
        </div>
        <div class="block" v-if="loginRegisterData.type == 1">
<!--          <div class="title">-->
<!--            <div>-->
<!--              <img class="middle" src="@/assets/images/component/l3.png" alt="">-->
<!--              <span class="middle">Login</span>-->
<!--            </div>-->
<!--          </div>-->
          <div class="sr">
<!--            <div class="divFlex">-->
<!--              <img class="demo" src="@/assets/images/component/l1.png" alt="">-->
<!--              <input v-model="loginRegisterData.loginInfo.username" type="text" placeholder="Nome de Usuário">-->
<!--              <img v-if="loginRegisterData.loginInfo.username" class="close" src="@/assets/images/component/close2.png" @click="loginRegisterData.loginInfo.username = ''" alt="">-->
<!--            </div>-->
<!--            <div class="divFlex">-->
<!--              <img class="demo" src="@/assets/images/component/l2.png" alt="">-->
<!--              <input v-model="loginRegisterData.loginInfo.password" type="password" placeholder="Senha">-->
<!--              <img v-if="loginRegisterData.loginInfo.password" class="close" src="@/assets/images/component/close2.png" @click="loginRegisterData.loginInfo.password = ''" alt="">-->
<!--            </div>-->
            <p style="color: #FFFFFF;font-size: .22rem;line-height: 1.28;padding: 0 .3rem .07rem 0;">Suporta apenas ContaLogin</p>
            <el-form :model="loginRegisterData.loginInfo" :rules="loginRegisterData.loginInfo.rules" ref="loginRef" label-width="0">
              <el-form-item prop="username">
                <el-input v-model="loginRegisterData.loginInfo.username" prefix-icon="icon-username" clearable  placeholder="Nome de Usuário"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="loginRegisterData.loginInfo.password"  prefix-icon="icon-password" placeholder="Senha" show-password type="password" clearable></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="desc divFlex">
            <div @click="loginRegisterData.loginInfo.automatic = !loginRegisterData.loginInfo.automatic">
              <div class="middle choose" :style="loginRegisterData.loginInfo.automatic?'border: 0':''">
                <img v-if="loginRegisterData.loginInfo.automatic" src="@/assets/images/component/choose.png" alt="">
              </div>
              <i class="middle">Lembre-se da senha da conta</i>
            </div>
            <span></span>
          </div>
          <div class="btn">
            <div @click="loginBtn">Login</div>
          </div>
        </div>
        <div class="block"  v-if="loginRegisterData.type == 2">
<!--          <div class="title">-->
<!--            <div>-->
<!--              <img class="middle" src="@/assets/images/component/l3.png" alt="">-->
<!--              <span class="middle">Registre sua conta</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="choose">-->
<!--            <div>-->
<!--              <span :class="loginRegisterData.telAndMail == '1'?'active':''" @click="loginRegisterData.telAndMail = 1;loginRegisterData.registerInfo.username = ''">{{$t('login.l10')}}</span>-->
<!--              <span :class="loginRegisterData.telAndMail == '2'?'active':''" @click="loginRegisterData.telAndMail = 2;loginRegisterData.registerInfo.username = ''">{{$t('login.l11')}}</span>-->
<!--            </div>-->
<!--          </div>-->
          <div class="sr">
<!--            <div class="divFlex">-->
<!--              <img class="demo" src="@/assets/images/component/l1.png" alt="" style="height: .25rem;">-->
<!--              <input v-model="loginRegisterData.registerInfo.username" type="text" placeholder="Nome de Usuário">-->
<!--              <img v-if="loginRegisterData.registerInfo.username" @click="loginRegisterData.registerInfo.username = ''" class="close" src="@/assets/images/component/close2.png" alt="">-->
<!--            </div>-->
<!--            <div class="divFlex">-->
<!--              <img class="demo" src="@/assets/images/component/l2.png" alt="">-->
<!--              <input v-model="loginRegisterData.registerInfo.password" :type="loginRegisterData.passwordEye?'text':'password'" placeholder="Senha">-->
<!--              <img v-if="loginRegisterData.passwordEye" style="width: .28rem" src="@/assets/images/component/l6.png" alt="" @click="loginRegisterData.passwordEye = !loginRegisterData.passwordEye">-->
<!--              <img v-else style="width: .28rem" src="@/assets/images/component/l7.png" alt="" @click="loginRegisterData.passwordEye = !loginRegisterData.passwordEye">-->
<!--            </div>-->
<!--            <p style="margin-bottom: .14rem;"><progress-com :value="loginRegisterData.registerInfo.password"/></p>-->
<!--            <div class="divFlex">-->
<!--              <img class="demo" src="@/assets/images/component/l2.png" alt="">-->
<!--              <input v-model="loginRegisterData.registerInfo.truePassword" :type="loginRegisterData.truePasswordEye?'text':'password'" placeholder="Confirme a senha novamente, o mesmo que a senha!">-->
<!--              <img v-if="loginRegisterData.truePasswordEye" style="width: .28rem" src="@/assets/images/component/l6.png" alt="" @click="loginRegisterData.truePasswordEye = !loginRegisterData.truePasswordEye">-->
<!--              <img v-else style="width: .28rem"  src="@/assets/images/component/l7.png" alt="" @click="loginRegisterData.truePasswordEye = !loginRegisterData.truePasswordEye">-->
<!--            </div>-->
<!--            <div class="divFlex phone">-->
<!--              <div>-->
<!--                <img class="middle" style="height: .3rem;margin: 0 .12rem 0 .08rem;" src="@/assets/images/component/l5.png" alt="">-->
<!--                <span class="middle">+55</span>-->
<!--                <img class="middle" style="height: .12rem;margin-left: .3rem;" src="@/assets/images/component/next.png" alt="">-->
<!--              </div>-->
<!--              <input v-model="loginRegisterData.registerInfo.mobile" style="width: 85%" type="number" placeholder="Digite o Número do Celular" />-->
<!--              <img v-if="loginRegisterData.registerInfo.mobile" @click="loginRegisterData.registerInfo.mobile = ''" class="close" src="@/assets/images/component/close2.png" alt="">-->
<!--            </div>-->
<!--            <div class="divFlex">-->
<!--              <img class="demo" src="@/assets/images/index/card.png" alt="" style="height: .3rem;left: .2rem;">-->
<!--              <input v-model="loginRegisterData.registerInfo.realName" type="text" placeholder="Preencha o nome verdadeiro e torne -o conveniente para a retirada posterior!">-->
<!--              <img v-if="loginRegisterData.registerInfo.realName" @click="loginRegisterData.registerInfo.realName = ''" class="close" src="@/assets/images/component/close2.png" alt="">-->
<!--            </div>-->
<!--            <p style="margin: -.36rem 0 .36rem 0;font-size: .18rem;">Apenas são permitidas letras maiúsculas</p>-->
<!--            <div class="divFlex">-->
<!--              <img class="demo" src="@/assets/images/component/l4.png" alt="" style="height: .4rem;left: 0.16rem;">-->
<!--              <span class="middle" style="color: #fff;">BRL（BRL）</span>-->
<!--              <img style="width: .2rem" src="@/assets/images/component/next.png" alt="">-->
<!--            </div>-->
<!--            <p style="margin: -.36rem 0 .36rem 0;font-size: .18rem">A moeda determina os jogos de terceiros e os métodos de pagamento e não pode ser modificada.</p>-->
            <p style="color: #FFFFFF;font-size: .22rem;line-height: 1.28;padding: 0 .3rem .07rem 0;">Suporta apenas ContaRegistro</p>
            <el-form :model="loginRegisterData.registerInfo" :rules="loginRegisterData.registerInfo.rules" ref="registerRef" label-width="0">
              <el-form-item prop="username">
                <el-input v-model="loginRegisterData.registerInfo.username" prefix-icon="icon-username" clearable  placeholder="Nome de Usuário"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="loginRegisterData.registerInfo.password"  prefix-icon="icon-password" placeholder="Senha" show-password type="password" clearable></el-input>
              </el-form-item>
              <p><progress-com :value="loginRegisterData.registerInfo.password"/></p>
              <el-form-item prop="truePassword">
                <el-input v-model="loginRegisterData.registerInfo.truePassword"  prefix-icon="icon-password" placeholder="Confirme a senha novamente, o mesmo que a senha!" show-password type="password" clearable></el-input>
              </el-form-item>
              <el-form-item prop="realName">
                <el-input v-model="loginRegisterData.registerInfo.realName" prefix-icon="icon-realName" clearable  placeholder="Preencha o nome verdadeiro e torne -o conveniente para a retirada posterior!"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="loginRegisterData.registerInfo.realName" prefix-icon="icon-money" readonly value="BRL(BRL)" placeholder="Preencha o nome verdadeiro e torne -o conveniente para a retirada posterior!"></el-input>
              </el-form-item>
            </el-form>
            <p style="margin: -.36rem 0 .36rem 0;font-size: .18rem">A moeda determina os jogos de terceiros e os métodos de pagamento e não pode ser modificada.</p>
          </div>
          <div class="desc divFlex" style="align-items: flex-start;"  @click="loginRegisterData.registerInfo.automatic = !loginRegisterData.registerInfo.automatic">
            <div>
              <div class="middle choose" :style="loginRegisterData.registerInfo.automatic?'border: 0':''">
                <img v-if="loginRegisterData.registerInfo.automatic" src="@/assets/images/component/choose.png" alt="">
              </div>
            </div>
            <div style="width: calc(100% - .45rem);"><i>Tenho mais de 18 anos, li e aceito</i><span> 《Acordo de Usuário》</span></div>
          </div>
          <div class="btn">
            <div @click="registerBtn">Registro</div>
          </div>
        </div>
        <div class="desc divFlex" style="justify-content: space-around;">
          <span>Suporte ao cliente</span>
          <span>Jogar Grátis</span>
          <span v-if="loginRegisterData.type === 1">Esqueceu a Senha？</span>
        </div>
<!--        <div class="desc divFlex" style="justify-content: center" @click="customerJump">-->
<!--          <span>{{$t('login.l9')}}</span>-->
<!--        </div>-->
      </div>
      <div class="lr-mb-content" v-else>
        <div class="cp-body">
          <div class="title">Esqueceu a Senha</div>
          <div class="step">
            <el-steps :active="1" align-center>
              <el-step title="Verificação de ID" />
              <el-step title="Redefinição de Senha"/>
              <el-step title="Terminar"/>
            </el-steps>
          </div>
          <div class="body divFlex">
            <img class="demo" src="@/assets/images/component/l1.png" alt="">
            <input type="text" placeholder="Nome de Usuário">
            <img class="close" src="@/assets/images/component/close2.png" @click="loginRegisterData.loginInfo.username = ''" alt="">
          </div>
          <div class="btn">Seguintes</div>
        </div>
      </div>
      <img  @click="closeTk" src="@/assets/images/component/close.svg" alt="">
    </div>
  </transition>
</div>
</template>
<style lang="scss">
.cp-body .el-step__title{
  margin-top: .07rem;
  font-size: .2rem;
  line-height: .24rem;
  color: $font-color2 !important;
}
.cp-body .is-finish.el-step__title{
  color: $font-color4 !important;
}
.cp-body .el-step__icon{
  background-color: $bg-color1 !important;
}
.cp-body .el-step__head{
  border-color: $bg-color3 !important;
  color: $bg-color3 !important;
}
.cp-body .el-step__head.is-finish {
  border-color: $font-color4 !important;
  color:$bg-color1 !important;
}
.cp-body .is-finish .el-step__icon.is-text{
  background-color: $font-color4 !important;
}
icon-username{
  background-image: url("@/assets/images/component/l1.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px; /* 根据需要设置宽高 */
  height: 12px;
  position: relative;
  &::before{
    content: "*";
    color: red;
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 10px;
  }
}
icon-password{
  background-image: url("@/assets/images/component/l2.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px; /* 根据需要设置宽高 */
  height: 14px;
  &::before{
    content: "*";
    color: red;
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 10px;
  }
}
icon-realName{
  background-image: url("@/assets/images/index/card.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px; /* 根据需要设置宽高 */
  height: 14px;
  &::before{
    content: "*";
    color: red;
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 10px;
  }
}
icon-money{
  background-image: url("@/assets/images/component/l4.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px; /* 根据需要设置宽高 */
  height: 14px;
  &::before{
    content: "*";
    color: red;
    position: absolute;
    right: -2px;
    top: -10px;
    font-size: 10px;
  }
}
</style>

<style scoped lang="scss">
.mask{
  z-index: 1002;
}
.lr-main{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1002;
  .lr-m-body{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 100%;
    z-index: 1002;
    >.lr-mb-content{
      width: 6.9rem;
      background-color: $bg-color1;
      border-radius: .12rem;
      padding: 0 .3rem .2rem;
      margin: 0 auto;
      .title{
        >div{
          width: 48%;
          padding: 0 .1rem;
          margin: 0 auto;
          line-height: .8rem;
          text-align: center;
          cursor: pointer;
          &.active{
            border-bottom: .04rem solid $font-color4;
            span{
              color: $font-color4;
            }
          }
          span{
            color: $font-color1;
            font-size: .26rem;
          }
          img{
            width: .26rem;
            margin-right: .08rem;
          }
        }
      }
      .choose{
        >div{
          width: 90%;
          margin: 0 auto;
          border: .01rem solid $bg-color3;
          text-align: center;
          border-radius: .64rem;
          overflow: hidden;
          span{
            display: inline-block;
            width: 50%;
            line-height: .64rem;
            &.active{
              color: $font-color1;
              background-color: $font-color4;
            }
          }
        }
      }
      .sr{
        >div{
          padding: 0 .32rem 0 .6rem;
          height: .7rem;
          border-radius: .12rem;
          border: .01rem solid $bg-color3;
          margin-bottom: .5rem;
          position: relative;
          &.phone{
            padding: 0 .24rem;
            >div{
              width: 44%;
              border-right: .01rem solid $bg-color3;
            }
          }
        }
        img{
          &.demo{
            position: absolute;
            left: .24rem;
            top: 50%;
            transform: translate(0, -50%);
            height: .25rem;
          }
          &.close{
            width: .28rem;
            height: .28rem;
          }
        }
        input{
          width: calc(100% - .32rem);
          padding: 0 .1rem;
          line-height: .68rem;
          color: #FFFFFF;
          font-size: .22rem;
        }
      }
      .desc{
        color: $font-color4;
        font-size: .22rem;
        .choose{
          width: .32rem;
          height: .32rem;
          border-radius: .12rem;
          border: .01rem solid $bg-color3;
          img{
            width: 100%;
          }
        }
        i{
          margin-left: .16rem;
          font-style: normal;
          color: #ffffff;
        }
      }
      .btn{
        >div{
          height: .68rem;
          line-height: .68rem;
          text-align: center;
          border-radius: .12rem;
          background-color: $font-color4;
          color: $font-color7;
        }
      }
      >div{
        margin-bottom: .2rem;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
      .block{
        >div{
          margin-bottom: .2rem;
          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }
    }
    >img{
      width: .56rem;
      height: .56rem;
      display: block;
      margin: .32rem auto 0;
    }
    .cp-body{
      padding: .2rem;
      .title{
        text-align: center;
        font-size: .3rem;
        padding-bottom: .2rem;
        color: $font-color1;
      }
      .step{
        border: .01rem solid $bg-color3;
        border-radius: .12rem;
        padding: .4rem 0;
      }
      .body{
        padding: 0 0.32rem 0 0.6rem;
        height: 0.7rem;
        border-radius: 0.12rem;
        border: 0.01rem solid $bg-color3;
        margin-bottom: .5rem;
        position: relative;
        margin-top: .2rem;
        img{
          &.demo{
            position: absolute;
            left: .24rem;
            top: 50%;
            transform: translate(0, -50%);
            height: .25rem;
          }
          &.close{
            width: .28rem;
            height: .28rem;
          }
        }
      }
      .btn{
        height: .7rem;
        line-height: .7rem;
        border-radius: .12rem;
        background-color: $font-color4;
        color: $bg-color1;
        text-align: center;
      }
    }
  }
}
</style>