import API from '@/utils/request'

// 通用信息
export function commonInfoPost (params,callback) {
    API.postRequest("api/index/info", params, function (res) {
        typeof callback === 'function' && callback(res);
    })
}
// 获取频道信息
export function userChannelGet(callback) {
    API.getRequest('api/user/channel',{},function(res) {
        typeof callback === 'function' && callback(res);
    })
}

// 首页游戏列表
export function indexGameListGet (callback) {
    API.getRequest('api/game/game/index',{
        slug:'recommend'
    },function(res) {
       typeof callback === 'function' && callback(res);
    })
}
//
export function gameProviderPageGet(params, callback) {
    API.getRequest('api/game/provider/page',params, (res) => {
        typeof callback === 'function' && callback(res);
    });
}

// 游戏列表
export function gamePageGet (params,callback) {
    API.getRequest('api/game/game/page',params, (res) => {
        typeof callback === 'function' && callback(res);
    }, true);
}
// 游戏记录列表
export function gameLogPageGet(params,callback){
    API.getRequest('api/game/gamelog/page',params, (res) => {
        typeof callback === 'function' && callback(res);
    }, true);
}
// 收藏游戏列表
export function gameFavPageGet(params,callback){
    API.getRequest('api/game/gamefav/page',params, (res) => {
        typeof callback === 'function' && callback(res);
    }, true);
}
export function gameFavAddPost (params,callback) {
    API.postRequest('api/game/gamefav/fav', params ,function(res) {
        typeof callback === 'function' && callback(res);
    }, true);
}
export function gameFavDelPost (params,callback) {
    API.postRequest('api/game/gamefav/delFav', params ,function(res) {
        typeof callback === 'function' && callback(res);
    }, true);
}

// 登录
export function loginPost (params,callback) {
    API.postRequest('api/login', params ,function(res) {
        typeof callback === 'function' && callback(res);
    }, true);
}
// 注册
export function registerPost (params,callback) {
    API.postRequest('api/register', params ,function(res) {
        typeof callback === 'function' && callback(res);
    }, true);
}
// 获取钱包信息
export function userWalletGet (callback) {
    API.getRequest("api/user/wallet",{},function(res){
        typeof callback === 'function' && callback(res);
    })
}
export function walletLogGet (params,callback) {
    API.getRequest("api/user/wallet/log",params,function(res){
        typeof callback === 'function' && callback(res);
    },true)
}

// 获取游戏信息
export function gameLoginPost (params,callback) {
    API.postRequest("api/game/game/login", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}
export function gameScoreOutScore (callback) {
    API.getRequest("api/game/score/outScore",{},function(res){
        typeof callback === 'function' && callback(res);
    })
}
export function gameScoreQueryScore (callback) {
    API.getRequest("api/game/score/queryScore",{},function(res){
        typeof callback === 'function' && callback(res);
    },true)
}
// 游戏上分订单
export function gameScorePageGet (params,callback) {
    API.getRequest("api/game/score/page",params,function(res){
        typeof callback === 'function' && callback(res);
    },true)
}

// 获取金额信息
export function betInfoGet (callback) {
    API.getRequest("api/user/betInfo", {},(res) => {
        typeof callback === 'function' && callback(res);
    })
}
// 充值列表
export function payChannelGet (callback) {
    API.getRequest("api/pay/channel/list", {},(res) => {
        typeof callback === 'function' && callback(res);
    })
}
export function payOptionGet (callback) {
    API.getRequest("api/pay/option/list", {},(res) => {
        typeof callback === 'function' && callback(res);
    })
}
export function payOrderPost(params,callback) {
    API.postRequest("api/pay/order", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}

// 提现列表
export function withdrawListPost(callback) {
    API.postRequest("api/withdraw/list", {}, (res) => {
        typeof callback === 'function' && callback(res);
    })
}
export function withdrawOrder(params,callback) {
    API.postRequest("api/withdraw/order", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}
export function setWithdrawPasswordPost(params,callback) {
    API.postRequest("api/user/setWithdrawPassword", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}
export function walletAccountListGet(callback) {
    API.getRequest("api/user/wallet/account/list", {},(res) => {
        typeof callback === 'function' && callback(res);
    })
}
export function walletAccountDel(params,callback) {
    API.postRequest("api/user/wallet/account/del", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}
export function walletAccountModify(params,callback) {
    API.postRequest("api/user/wallet/account/modify", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}
export function walletAccountAdd(params,callback) {
    API.postRequest("api/user/wallet/account/add", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}
export function walletAccountDefault(params,callback) {
    API.postRequest("api/user/wallet/account/default", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}



//VIP
//获取VIP等级列表
export function levelListGet (callback) {
    API.getRequest("api/level/list", {},(res) => {
        typeof callback === 'function' && callback(res);
    })
}


// 赌博游戏记录
export function gameBetPageGet (params,callback) {
    API.getRequest("api/game/gamebet/page", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}

// 转盘游戏
export function boxListGet(_tabId,callback) {
    API.getRequest('api/box/list/' + _tabId,{},(res) => {
        typeof callback === 'function' && callback(res)
    })
}
export function promotionSpinTimesGet(callback) {
    API.getRequest('api/user/promotion/spinTimes',{}, (res) => {
        typeof callback === 'function' && callback(res)
    })
}
export function userPromotionSpin(callback) {
    API.postRequest('api/user/promotion/spin',{}, (res) => {
        typeof callback === 'function' && callback(res)
    })
}

// 获取用户信息
export function userInfoGet(callback) {
    API.getRequest('api/user/info',{}, (res) => {
        typeof callback === 'function' && callback(res)
    })
}
export function userUpdateInfo(params,callback) {
    API.postRequest('api/user/updateInfo',params, (res) => {
        typeof callback === 'function' && callback(res)
    },true)
}
export function userUpdatePwd(params,callback) {
    API.postRequest('api/user/updatePwd',params, (res) => {
        typeof callback === 'function' && callback(res)
    },true)
}

// 留言中心
export function customerPageGet(params,callback) {
    API.getRequest('api/customer/page',params, (res) => {
        typeof callback === 'function' && callback(res)
    })
}
export function announcementPageGet (params,callback) {
    API.getRequest("api/announcement/page", params, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}
export function announcementDetailGet (id,callback) {
    API.getRequest("api/announcement/" + id, {}, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}
export function feedbackPageGet (callback) {
    API.getRequest("api/feedback/page", {}, function (res) {
        typeof callback === 'function' && callback(res);
    }, true)
}
export function feedbackSavePost(params,callback) {
    API.postRequest('api/feedback/save',params, (res) => {
        typeof callback === 'function' && callback(res)
    },true)
}

// 团队数据统计
export function userTeamDataGet(params,callback) {
    API.getRequest('api/user/team/data',params, (res) => {
        typeof callback === 'function' && callback(res)
    },true)
}
