export default{
    components: {
        f1: '首页',
        f2: '晋升',
        f3: '代理',
        f4: '订单',
        f5: '我的',
        f6: '登录',
        f7: '空数据',
        b1: "确定",
        b2: "取消",
    },
    index: {
        i1: '存款',
        i2: '撤回',
        i3: '登录',
        i4: '注册',
        i5: '欢迎来到 BBJJ.COM，最好的游戏之家！作为PG SLOTS全球领先的合作伙伴',
        i6: '更多',
        t1: '最近',
        t2: '投注记录',
        t3: '晋升',
        t4: '轮盘赌',
        t5: '贵宾',
        t6: '胸部',
        t7: '资金',
        t8: '奖品',
        t9: '代理',
        t10: '葡萄牙语',
        t11: '下载应用程序',
        t12: '联系支持',
        t13: '常见问题解答',
    },
    login: {
        l1: '登录',
        l2: '输入用户名',
        l3: '输入密码',
        l4: '自动登录',
        l5: '忘记密码',
        l6: '注册',
        l7: '电话',
        l8: '通信',
        l9: '在线服务',
        l10: '电话',
        l11: '通信',
        l12: '手机号码',
        l13: '您的电子邮件地址',
        l14: '您的邀请码',
        l15: '输入密码',
        l16: '请确认您的密码',
        l17: '我确认我已年满 18 周岁并已阅读服务条款',
        l18: '实验游戏',
    },
    my: {
        m1: '支持',
        m2: '消息',
        m3: '数据',
        m4: '账户',
        m5: '恢复平衡',
        m6: '提取',
        m7: '存款',
        m8: '费用',
        m9: '充值雷亚尔',
        m10: '晋级投注',
        m11: '恢复平衡',
        m12: '晋升',
        m13: '投注记录',
        m14: '报告',
        m15: '提币管理',
        m16: '邀请',
        m17: '数据',
        m18: '安全中心',
        m19: '音乐',
        m20: '常见问题解答',
        m21: '观点',
        m22: '退出',
        w1: '恢复平衡',
        w2: '雷亚尔',
        w3: '当前主钱包',
        w4: '我的货币',
        w5: '卡片',
    },
    team: {
        t1: '邀请',
        t2: '推广教程',
        t3: '邀请链接',
        t4: '佣金',
        t5: '表现',
        t6: '所有数据',
        t7: '下属财务状况',
        t8: '下属投注',
        t9: '从属投注',
        t10: '下属统计',
        t11: '下属要求',
        t12: '佣金费'
    },
    game: {
        g1: '订单',
        g2: '游戏历史',
        g3: '投注单总数',
        g4: '总收入',
        g5: '总费用',
        g6: '今天',
        g7: '昨天',
        g8: '本周',
        g9: '投注金额',
        z1: '轮盘赌',
        z2: '抽奖次数',
        z3: '命运之轮',
        z4: '总计',
        z5: '获奖通知',
        z6: '获奖记录',
        z7: '抽奖',
        z8: '轮盘赌',
    },
    opinion: {
        o1: '意见',
        o2: '意见类型',
        o3: '活动建议',
        o4: '请输入标题',
        o5: '请输入内容',
        o6: '发送',
        o7: '活动建议',
        o8: '功能建议',
        o9: '游戏建议',
        o10: '其他问题',
        o11: '提交成功',
    },
    funds: {
        f1: '资金',
        f2: '账户变更记录',
        f3: '一切',
        f4: '全部',
        wt1: "充值",
        wt2: "提现",
        wt3: "真金投注",
        wt4: "赏金投注",
        wt5: "中奖",
        wt6: "活动赏金",
        wt7: "返佣真金",
        wt8: "手续费",
        wt9: "人工调账",
        wt10: "退款",
        wt11: "游戏平台赠送",
        wt12: "取消投注",
        wt13: "优惠券",
        wt15: "人工调增真金",
        wt16: "人工调减真金",
        wt17: "人工调增赏金",
        wt18: "人工调减赏金",
        wt19: "Null",
        w1: '充值',
        w2: '提现',
        w3: '真金投注',
        w4: '赏金投注',
        w5: '中奖真金',
        w6: '游戏平台调增金额',
        w7: '游戏平台调减金额',
        w8: '中奖赏金',
        w9: '活动赏金',
        w10: '薪金发放',
        w11: '个人投注返佣',
        w12: '下级投注返佣',
        w13: '邀请首次充值返佣',
        w14: '累计邀请人数返佣',
        w15: '手续费',
        w16: '人工调增真金',
        w17: '人工调减真金',
        w18: '人工调增赏金',
        w19: '人工调减赏金',
        w20: '人工调增提现余额',
        w21: '人工调减提现余额',
        w22: '退款',
        w23: '游戏平台赠送',
        w24: '取消投注',
        w25: '优惠券',
        w26: '排行榜'
    },

}