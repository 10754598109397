<template>
    <div>
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'PcCom',
    components: {

    },
    data() {
        return {

        }
    },
    watch: {

    },
    mounted() {

    },
    methods: {

    }
}
</script>
<style>

</style>