<script setup>

import {defineEmits, defineProps, onMounted, onUnmounted, reactive, ref, watch} from "vue";

const props = defineProps({
  showList: Array,
  params: String
})
const emit = defineEmits(['choose']);
const selectComponentRef = ref(null);
const scData = reactive({
  list: props.showList,
  value: 0,
  text: '',
  show: false
})

const dataInit = () => {
  scData.list = props.showList;
  for(let i in scData.list) {
    if(i == 0) {
      scData.value = scData.list[i].value;
      scData.text = scData.list[i].text;
    }
  }
}

const changeName = (e) => {
  let html;
  for(let i in scData.list) {
    if(scData.list[i].value == e) {
      html = scData.list[i].text
    }
  }
  scData.value = e;
  scData.text = html;
  scData.show = false;
  emit('choose',props.params,scData.value);
}

watch(() => props.params, () => {
  dataInit()
});

const handleClick = (event) => {
  if(selectComponentRef.value && !selectComponentRef.value.contains(event.target)){
    scData.show = false;
  }
}

onMounted(() => {
  dataInit()
  window.addEventListener('click',handleClick);
})

onUnmounted(() => {
  window.removeEventListener('click',handleClick);
})
</script>

<template>
<div class="selectMain">
  <div ref="selectComponentRef" @click="scData.show = !scData.show" class="ellipsis">{{scData.text}}</div>
  <img src="@/assets/images/component/next.png" alt="">
  <ul v-if="scData.show">
    <li @click="changeName(item.value)" v-for="item in scData.list" :key="item.value">{{item.text}}</li>
  </ul>
</div>
</template>

<style scoped lang="scss">
.selectMain{
  display: inline-block;
  max-width: 3.15rem;
  min-width: 1.6rem;
  width: auto !important;
  padding: 0 .55rem 0 .2rem;
  background-color: $bg-color1;
  border-radius: .48rem;
  border: .01rem solid $bg-color3;
  position: relative;
  cursor: pointer;
  >img{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    right: .16rem;
    width: .18rem;
  }
  div{
    font-size: .2rem;
    color: $font-color2;
    line-height: .48rem;
  }
  ul{
    position: absolute;
    left: 0;
    top: .55rem;
    border-radius: .12rem;
    background-color: $bg-color1;
    border: .01rem solid $bg-color3;
    width: auto;
    padding: .12rem 0;
    max-height: 6rem;
    overflow-y: scroll;
    li{
      padding: .1rem .2rem;
      width: 100%;
      line-height: .5rem;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}
</style>