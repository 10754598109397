import router from '../router/index'
import store from '@/store';
import {gameScoreOutScore} from '@/utils/api'
// 路由守卫
router.beforeEach((to, from, next) => {
        // 全局添加邀请码
        if((JSON.stringify(to.query) != '{}' && to.query.id) || (JSON.stringify(from.query) != '{}' && from.query.id) || to.fullPath.indexOf('id') > -1 || from.fullPath.indexOf('id') > -1) {
            let _shareCode = to.query.id?to.query.id:from.query.id;
            if(!_shareCode) {
                if(to.fullPath.indexOf('id') > -1) {
                    _shareCode = to.fullPath.slice(to.fullPath.indexOf('id=')+10,to.fullPath.length)
                } else {
                    _shareCode = from.fullPath.slice(from.fullPath.indexOf('id=')+10,from.fullPath.length)
                }
            }
            store.commit('setShareCode',_shareCode)
            localStorage.setItem('shareCode', _shareCode)
            to.query.id = _shareCode;
            if(to.fullPath.indexOf('id=') == -1) {
                if(to.fullPath.indexOf('?') > -1) {
                    to.fullPath = to.fullPath + '&id=' + _shareCode;
                } else {
                    to.fullPath = to.fullPath + '?id=' + _shareCode;
                }
            }
        } else {
            store.commit('setShareCode','')
        }
        // 全局监听游戏返回功能
        if(from.name == 'GameDetail' && from.query.type && from.query.type == '3') {
            gameScoreOutScore();
        }
        next();
})

router.afterEach((to, from) => {
    // 路由跳转，每次滚动到顶部
    window.scrollTo(0,1);
})